// Author: Çağrı Erdem, 2024
// Description: Participant data handling script for the groove2tap study.


// Function to get user's geographic location using a Geo IP service
async function getUserGeoData() {
    try {
      const response = await fetch('https://ipapi.co/json/'); // Using ipapi.co as an example; ensure compliance with privacy policies
      if (!response.ok) throw new Error('Failed to fetch geo-data');
      const { city, country } = await response.json();
      return { city, country };
    } catch (error) {
      console.error('Error fetching user geo-data:', error);
      return { city: 'Unknown', country: 'Unknown' }; // Fallback values in case of an error
    }
  }
  
  // Function to collect and structure the tapping data and metadata
export async function collectTappingData(participantId, grooveId, takeNumber, rhythmArray, rating) {
    const timestamp = new Date().toISOString();
    const geoData = await getUserGeoData(); // Fetch user's geo-data
  
    // Structure the collected data and metadata into a JSON object
    const data = {
      filename: `${participantId}_${grooveId}_t${takeNumber}_r${rating}_${timestamp}`, // Structured identifier
      participantId,
      grooveId,
      takeNumber,
      timestamp,
      rhythmArray,
      rating,
      geoData
    };
  
    console.log('Collected Data:', data); // For debugging purposes
    return data; // Return the structured JSON object
  }
  