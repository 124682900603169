// Author: Çağrı Erdem, 2023--2024
// Description: main Parcel bundling script for the groove2tap study

//Importing modules:
// import "./../styles/mainUI.css";
import "./ui.js";
import "./utils/metronome.js";
// import "./workers/workerAjax.js";
import "./workerData.js";


